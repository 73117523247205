<template>
  <div class="menu-wrap">
    <el-menu
      :default-active="$route.name"
      :router="true"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
      :default-openeds="openeds"
    >
      <!-- <el-menu-item index="myCenter/course">
          <i class="el-icon-monitor"></i>
          <span slot="title">课程</span>
      </el-menu-item>-->

      <template
        v-for="(item, index) in menuData"
        :index="item.url"
        @click="handleMenu"
      >
        <div class="content" :key = "index">
          <el-submenu v-if="item.children.length > 0" :index="item.url">
            <template slot="title" >
              <i class="el-icon-pie-chart"></i>
              <span slot="title">{{ item.name }}</span>
            </template>
            <el-menu-item
                v-for="item2 in item.children"
                :key="item2.id"
                :index="item2.url"
                @click="handleMenu"
            >
              <i :class="item2.icon"></i>
              <span slot="title">{{ item2.name }}</span>
            </el-menu-item>
            <!-- <el-menu-item-group title="分组2">
              <el-menu-item index="1-3">选项3</el-menu-item>
            </el-menu-item-group> -->
            <!-- <el-submenu index="1-4">
              <span slot="title">选项4</span>
              <el-menu-item index="1-4-1">选项1</el-menu-item>
            </el-submenu> -->
          </el-submenu>

          <el-menu-item
            v-else
            :key="item.id"
            :index="item.url"
            @click="handleMenu"
          >
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </div>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      openeds: ["study"],
      menuData: [
        // data
        {
          id: 1,
          name: "我的学习",
          // icon: 'el-icon-pie-chart',
          url: "study",
          children: [
            {
              id: 11,
              name: "我的课程",
              icon: "el-icon-reading",
              url: "course",
            },
            /* { //2023-2-20 不要
              id: 12,
              name: "我的笔记",
              icon: "el-icon-notebook-2",
              url: "notebooks",
            }, */
            {
              id: 13,
              name: "我的教材",
              icon: "el-icon-collection",
              url: "textbook",
            },
            /* {  // 不要
            id: 14,
            name: '我的作业',
            icon: 'el-icon-notebook-1',
            url: 'homework'
          },
          {
            id: 15,
            name: '换课申请',
            icon: 'el-icon-folder-add',
            url: 'changeCourses'
          }, */
          ],
        },
        {
          id: 4,
          name: "我的考试",
          icon: "el-icon-takeaway-box",
          url: "testpaper",
          children: [],
        },
        {
          id: 3,
          name: "我的证书",
          icon: "el-icon-trophy",
          url: "certificate",
          children: [],
        },
        {
          id: 15,
          name: "实名认证",
          icon: "el-icon-notebook-1",
          url: "approval",
          children: [],
        },
        {
          id: 5,
          name: "我的订单",
          icon: "el-icon-s-order",
          url: "orderlist",
          children: [],
        },
        {
          id: 6,
          name: "我的发票",
          icon: "el-icon-s-ticket",
          url: "invoice",
          children: [],
        },
        {
          id: 12,
          name: "消息通知",
          icon: "el-icon-chat-line-round",
          url: "message",
          children: [],
        },
        {
          id: 2,
          name: "基本信息",
          icon: "el-icon-s-custom",
          url: "basicinfo",
          children: [],
        },
        // {
        //   id: 8,
        //   name: "我的简历",
        //   icon: "el-icon-document-remove",
        //   url: "resume",
        //   children: [],
        // },
        /* { 2023-2-20 
          id: 9,
          name: "我的积分",
          icon: "el-icon-s-data",
          url: "integral",
          children: [],
        }, */
        /* { //不要
          id: 10,
          name: "升级会员",
          icon: "el-icon-s-marketing",
          url: "member",
          children: [],
        }, */
        {
          id: 14,
          name: "安全设置",
          // icon: 'el-icon-pie-chart',
          url: "setting",
          children: [
            {
              id: 7,
              name: "手机号解绑",
              icon: "el-icon-unlock",
              url: "unbinding",
              children: [],
            },
            {
              id: 11,
              name: "密码修改",
              icon: "el-icon-refresh-right",
              url: "password",
              children: [],
            },
            /* {
              id: 13,
              name: "账号注销",
              icon: "el-icon-s-release",
              url: "cancellation",
              children: [],
            }, */
          ]
        }
      ],
    };
  },
  mounted() {
    this.handleOpen();
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleMenu() {
      console.log(this.$router);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.menu-wrap {
  //margin-top: 10px;
  .el-menu {
    border-right: none;
    text-align: left;
  }
  .el-menu-vertical-demo {
    width: 100% !important;
  }
  .el-submenu {
    li {
      text-align: left;
    }
  }
  .el-menu-item-group {
    text-align: left;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
}
</style>