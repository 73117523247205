<template>
  <div class="my-center">
    <header-t />
    <nav2 />
    <!-- <div class="cont">
      <div class="notice">
        您还没有头像。拥有一个独有的头像，老师和同学们能更容易关注到你哦～～ 点击设置
      </div>
    </div> -->
    <div class="location">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="cont">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-card shadow="nerve" class="card">
            <div class="l-tit">个人中心</div>
            <left-menu/>
          </el-card>
        </el-col>
        <el-col :span="18">
          <router-view/>
        </el-col>
      </el-row>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderT from "@/components/HeaderT";
import Nav2 from "@/components/Nav2";
import Footer from "@/components/Footer";
import LeftMenu from "@/components/LeftMenu";
export default {
  name: 'MyCenter',
  components: {
    HeaderT,
    Nav2,
    Footer,
    LeftMenu
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.my-center{
  background: #f6f6f6;
  min-width: 1170px;
  .location{
    margin: 20px auto 0;
    // border:1px #f00 solid;
    width: 1170px;
    text-align: left;
  }
  .cont{
    margin: 16px auto 20px;
    width: 1170px;
    .notice{
      background-color:#fff4e4 ;
      color:#ffa51f;
      padding: 16px;
      margin-bottom: 24px;
      font-weight: 500;
    }
    .card{
      .l-tit{
        text-align: left;
        font-weight: 500;
        height: 40px;
        line-height: 40px;
        color:#333;
        border-bottom: 2px #ddd solid;
      }
    }
  }
}
</style>